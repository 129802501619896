<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tpm.course_module') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="course-module" :class="'btn btn_add_new font-weight-bold'">{{ $t('elearning_tpm.course_module') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-overlay :show="loading">
                        <b-row>
                        <b-col v-if="$store.state.Auth.activeRoleId === 1"  lg="6" sm="6">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                plain
                                v-model="formData.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                <v-select
                                      id="org_id"
                                      v-model="formData.org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_type_id"
                                  :options="trainingTypeList"
                                  id="training_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_type_id"
                                      v-model="formData.training_type_id"
                                      :reduce="op => op.value"
                                      :options="trainingTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_category_id"
                                      v-model="formData.training_category_id"
                                      :reduce="op => op.value"
                                      :options="trainingCategoryList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_title_id"
                                      v-model="formData.training_title_id"
                                      :reduce="op => op.value"
                                      :options="trainingTitleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <div class="col-md-12 dam-form">
                            <fieldset class="p-2 w-100">
                              <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.course_module')}}</legend>
                              <div class="col-12">
                                <table class="table" style="width:100%" border="1">
                                  <thead class="thead">
                                    <tr>
                                      <th style="width:18%">{{$t('elearning_tpm.course_module_en')}} <span class="text-danger">*</span></th>
                                      <th style="width:18%">{{$t('elearning_tpm.course_module_bn')}} <span class="text-danger">*</span></th>
                                      <th style="width:20%">{{$t('elearning_tpm.course_module_details_en')}}</th>
                                      <th style="width:20%">{{$t('elearning_tpm.course_module_details_bn')}}</th>
                                      <th style="width:15%">{{$t('globalTrans.attachment')}}</th>
                                      <th style="width:5%"></th>
                                    </tr>
                                  </thead>
                                  <tr v-for="(course_module, index) in formData.course_modules" :key="index">
                                    <td>
                                      <keep-alive>
                                        <ValidationProvider :vid="'course_module' + index" rules="required" name="Lesson Module (En)">
                                          <div slot-scope="{ valid, errors }">
                                            <b-form-input
                                              rows="2"
                                              v-model="course_module.course_module"
                                              :name="'course_modules['+index+'][course_module]'"
                                              :id="'course_modules['+index+'][course_module]'"
                                              :placeholder="$t('elearning_tpm.course_module_en')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                            <small v-if="attachments_errors && attachments_errors['course_modules.' + index + '.course_module']" class="text-danger">
                                              {{ attachments_errors['course_modules.'+index+'.course_module'] }}
                                            </small>
                                          </div>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </td>
                                    <td>
                                      <keep-alive>
                                        <ValidationProvider :vid="'course_module_bn' + index" rules="required"  name="Lesson Module (Bn)">
                                          <div slot-scope="{ valid, errors }">
                                            <b-form-input
                                              rows="2"
                                              v-model="course_module.course_module_bn"
                                              :name="'course_modules['+index+'][course_module_bn]'"
                                              :id="'course_modules['+index+'][course_module_bn]'"
                                              :placeholder="$t('elearning_tpm.course_module_bn')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                            <small v-if="attachments_errors && attachments_errors['course_modules.' + index + '.course_module_bn']" class="text-danger">
                                              {{ attachments_errors['course_modules.'+index+'.course_module_bn'] }}
                                            </small>
                                          </div>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </td>
                                    <td>
                                      <keep-alive>
                                        <ValidationProvider :vid="'course_module_details' + index"  name="Lesson Module Details (En)">
                                          <div slot-scope="{ valid, errors }">
                                            <b-form-textarea
                                              rows="2"
                                              v-model="course_module.course_module_details"
                                              :name="'course_modules['+index+'][course_module_details]'"
                                              :id="'course_modules['+index+'][course_module_details]'"
                                              :placeholder="$t('elearning_tpm.course_module_details')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="error invalid-feedback" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                            <small v-if="attachments_errors && attachments_errors['course_modules.' + index + '.course_module_details']" class="text-danger">
                                              {{ attachments_errors['course_modules.'+index+'.course_module_details'] }}
                                            </small>
                                          </div>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </td>
                                    <td>
                                      <keep-alive>
                                        <ValidationProvider :vid="'course_module_details_bn' + index" name="Lesson Module Details (Bn)">
                                          <div slot-scope="{ valid, errors }">
                                            <b-form-textarea
                                              rows="2"
                                              v-model="course_module.course_module_details_bn"
                                              :name="'course_modules['+index+'][course_module_details_bn]'"
                                              :id="'course_modules['+index+'][course_module_details_bn]'"
                                              :placeholder="$t('elearning_tpm.course_module_details_bn')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="error invalid-feedback" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                            <small v-if="attachments_errors && attachments_errors['course_modules.' + index + '.name']" class="text-danger">
                                              {{ attachments_errors['course_modules.'+index+'.name'] }}
                                            </small>
                                          </div>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </td>
                                    <td>
                                      <input type="hidden" :name="'course_modules['+index+'][id]'" v-model="course_module.id"/>
                                      <input type="hidden" :name="'course_modules['+index+'][course_id]'" v-model="course_module.course_id"/>
                                      <input type="hidden" :name="'course_modules['+index+'][pre_attachment]'" v-model="course_module.attachment"/>
                                      <b-form-file
                                        :name="'course_modules['+index+'][attachment]'"
                                        :data-id="course_module.id"
                                      ></b-form-file>
                                      <small v-if="attachments_errors && attachments_errors['course_modules.' + index + '.attachment']" class="text-danger">
                                        {{ attachments_errors['course_modules.'+index+'.attachment'] }}
                                      </small>
                                      <b v-if="course_module.pre_attachment">
                                        (<a target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + course_module.pre_attachment" download>{{$t('globalTrans.view_download')}}</a> )
                                      </b>
                                    </td>
                                    <td>
                                      <button @click="removeAttachment(index, course_module)" class="btn btn-sm btn-danger float-right" type="button">
                                        <i class="fas fa-window-close m-0"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="6">
                                      <button
                                        @click="addMore"
                                        class="btn btn-sm btn-primary float-right"
                                        type="button"
                                      ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </fieldset>
                          </div>
                        </b-row>
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <router-link to="course-module" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                          </div>
                        </div>
                      </b-overlay>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.dam-form .is-valid, .is-invalid {
  background-image: none !important;
  padding-right: 0px !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { courseModuleStore, courseModuleUpdate, trainingTitleModule } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$route.query.training_title_id) {
      const trTitle = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === parseInt(this.$route.query.training_title_id))
      if (typeof trTitle !== 'undefined') {
        this.formData.org_id = trTitle.org_id
        this.formData.training_type_id = trTitle.training_type_id
        this.formData.training_category_id = trTitle.training_category_id
        this.formData.training_title_id = parseInt(this.$route.query.training_title_id)
      }
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.formData.org_id = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        org_id: '',
        training_category_id: '',
        training_type_id: '',
        training_title_id: '',
        course_modules_remove: [],
        course_modules: [
          {
            id: '',
            course_module: null,
            course_module_bn: null,
            course_module_details: null,
            course_module_details_bn: null,
            status: 1,
            attachment: null
          }
        ]
      },
      officeTypeList: [],
      officeList: [],
      trainingCategoryList: [],
      attachments_errors: [],
      circularList: [],
      circularLoading: false,
      circularErrorMsg: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingCalendar: [],
      trainingTitleList: [],
      trainingTypeList: [],
      myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
    }
  },
  computed: {
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
    // trainingTypeList: function () {
    //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    // }
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.training_title_id': function (newValue) {
      this.formData.course_modules = this.getFormData()
    }
  },
  methods: {
    addMore () {
      this.formData.course_modules.push({
        id: '',
        course_module: null,
        course_module_bn: null,
        course_module_details: null,
        course_module_details_bn: null,
        attachment: null
      })
    },
    removeAttachment (index, data) {
      this.formData.course_modules.splice(index, 1)
      this.formData.course_modules_remove.push(data)
    },
    async getFormData () {
      this.loading = true
      const searchData = {
        org_id: this.formData.org_id,
        training_type_id: this.formData.training_type_id,
        training_category_id: this.formData.training_category_id,
        training_title_id: this.formData.training_title_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingTitleModule, searchData)
      if (!result.success) {
        this.formData.course_modules = [
          {
            id: '',
            course_module: null,
            course_module_bn: null,
            course_module_details: null,
            course_module_details_bn: null,
            attachment: null
          }
        ]
        this.formData.course_modules_remove = []
      } else {
        const courseList = result.data
        const atData = courseList.map(item => {
          const fileData = {}
          fileData.pre_attachment = item.attachment
          return Object.assign({}, item, fileData)
        })
        this.formData.course_modules = atData
        this.formData.course_modules_remove = []
      }
       this.loading = false
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === orgId)
      }
      return type
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    async createData () {
      this.loading = true
      let result = null
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData(document.getElementById('form'))
      Object.keys(this.formData).map(key => {
        if (key !== 'course_modules') {
          formData.append(key, this.formData[key])
        }
      })
      formData.append('course_modules_remove', JSON.stringify(this.formData.course_modules_remove))
      if (this.formData.id) {
        // formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${courseModuleUpdate}/${this.formData.id}`, formData, config)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, courseModuleStore, formData, config)
      }
      this.loading = false
      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/tpm/course-module')
      } else {
        this.attachments_errors = result.errors
        this.$refs.form.setErrors(result.errors)
      }
  }
  }
}
</script>
